<template>
  <div v-if="pageLoadging" class="root">
    <Detail v-if="fieldInfo" :info="fieldInfo" />
    <div class="root-main" id="root-main">
      <div class="root-main-list">
        <div class="scroll-tabs">
          <DuTabs
            :value="JSON.stringify(current)"
            @update:value="handleChangeCurrent"
            color="secondary"
          >
            <DuTab
              v-for="tab of filter"
              :name="JSON.stringify(tab)"
              :key="tab.sortType"
            >
              {{ tab.label }}
            </DuTab>
          </DuTabs>
        </div>

        <div v-if="auctions?.length" class="auctions">
          <Item
            v-for="(auctionItem, index) in auctions"
            :key="auctionItem?.auction_id"
            :auction="auctionItem"
            :currentTab="currentTab"
            :ubtName="ubtName"
            :isShowField="false"
            :isShowType="isShowType"
            :ubtIndex="index"
          />
        </div>
        <DuEmpty v-else text="暂无相关拍品" />

        <!-- @item-tap="handleItemTap(auctionItem)" -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { DuTabs, DuTab, DuEmpty } from "dangoui";

import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import AuctionApi from "@/apis/auction";
import Detail from "./components/detail.vue";
import Item from "./components/item.vue";
import { showToast, showLoadingToast, closeToast } from "vant";
const route = useRoute();

const auctions = ref([]);
const fieldInfo = ref(null);
const title = ref(null);
const filter = ref(null);
const current = ref(null);
const loadStatus = ref("");
const pageLoadging = ref(false);
let loading = false;
onMounted(async () => {
  if (route.query.fieldId) {
    await getField();

    await getAuctionList();
    pageLoadging.value = true;

    window.onscroll = async () => {
      const clientHeight = document.body.clientHeight;
      const y = window.scrollY;
      const contentHeight = document.getElementById("root-main").clientHeight;
      // 触底拉新
      if (y + clientHeight + 100 >= contentHeight) {
        if (loading) {
          return;
        }
        loading = true;
        await getAuctionList(true);
        loading = false;
      }
    };
  }
});
async function getField() {
  try {
    const { data } = await AuctionApi.getFieldDetail({
      id: route.query.fieldId,
    });
    fieldInfo.value = data?.fieldInfo;
    title.value = data?.fieldInfo.fieldName;
    filter.value = data.sorts;
    current.value = data.sorts[0];
  } catch (error) {
    showToast(error?.message || error);
  }
}
async function handleChangeCurrent(e) {
  current.value = JSON.parse(e);
  await getAuctionList();
}
async function getAuctionList(isMore = false) {
  if (!isMore) {
    auctions.value = [];
  }
  showLoadingToast({
    message: "加载中...",
    forbidClick: true,
  });
  const offset = isMore ? auctions.value.length : 0;
  const query = {
    limit: 20,
    offset: offset,
    fieldId: route.query.fieldId,
    sort: current.value?.sortType,
    showBlindAuction: true,
    cursor:
      isMore && auctions.value?.length
        ? auctions.value[auctions.value.length - 1].lastBidTime
        : "0",
  };
  const { data } = await AuctionApi.getFieldAuctions(query);
  closeToast();
  const list = data.items || [];
  auctions.value = isMore ? auctions.value.concat(list) : list;
  loadStatus.value =
    data.items && auctions.value.length < data.count ? "more" : "noMore";
}
</script>

<style lang="scss">
.root {
  position: relative;
  background: url(https://public.qiandaocdn.com/interior/images/72f66242404181419701de46d1394bb2.jpg)
    no-repeat top;
  background-size: 100% 812px;
  min-height: 100vh;
  .scroll-tabs {
    .du-tabs__list {
      /* 隐藏垂直滚动条 */
      &::-webkit-scrollbar {
        width: 0;
      }

      /* 隐藏水平滚动条 */
      &::-webkit-scrollbar {
        height: 0;
      }
      width: 100vw;
      overflow-x: scroll;
    }
    overflow-x: scroll;
  }
  .navbar {
    gap: 10rpx;
    height: 100%;
    .otext {
      display: flex;
      flex: 1;
      align-items: center;
      width: 0;

      color: var(--c-neutral-9);
      font-weight: 500;
      font-size: 34rpx;
      line-height: 64rpx;
    }
  }
  .scroll-tabs {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 9;
  }
  &-main {
    position: absolute;

    width: 100%;
    min-height: 100vh;
    .du-empty {
      height: 0;
      flex: 1;
    }
    &-list {
      min-height: 100vh;
      padding-bottom: env(safe-area-inset-bottom);

      background: #fff;
    }
    &::before {
      display: block;
      width: 100%;
      height: 16rpx;

      background: #fff;
      border-radius: 24rpx 24rpx 0 0;

      content: "";
    }
    .auctions {
      padding: 20rpx 24rpx;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      justify-content: space-between;
      gap: 16rpx;
    }
  }
}
</style>
